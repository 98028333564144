
let userData = JSON.parse(localStorage.getItem('userData'));
console.log(userData);
let routes;
let adminroutes = [
  {
    header: "Masters",
  }
  ,
  {
    title: "Categories",
    route: 'master-categories-list',
    icon: 'FolderIcon',
    action: 'read',
    resource: 'ACL',
  },


  {
    title: 'Ingredients',
    route: 'master-ingredients-list',
    icon: 'FolderIcon',
  },




  {
    title: 'Side Dishes',
    route: 'master-sidedish-list',
    icon: 'FolderIcon',
  },

  {
    title: 'Pincode',
    route: 'master-pincode-list',
    icon: 'FolderIcon',
  },


  {
    title: 'Time Schedule',
    route: 'master-schedule-list',
    icon: 'FolderIcon',
    action: 'read',
  },
  {
    title: 'Reviews',
    route: 'master-review-list',
    icon: 'FolderIcon',
  },
  {
    header: "Common",
  }
  ,
  {
    title: "Items",
    route: 'master-food-list',
    icon: 'FolderIcon',
  }

  ,
  {
    title: "Orders",
    route: 'master-orders-list',
    icon: 'FolderIcon',
  }

  ,
  {
    title: "General Settings",
    route: 'master-delivery-list',
    icon: 'FolderIcon',
  }
  ,



  {
    header: "User",
  }
  ,
  {
    title: "Members",
    route: 'master-members-list',
    icon: 'FolderIcon',
  }

  ,
  {
    title: "Staff Users",
    route: 'master-admins-list',
    icon: 'FolderIcon',
  }
]

let staffroutes = [
  {
    header: "Menu",
  },
  {
    title: "Orders",
    route: 'master-orders-list',
    icon: 'FolderIcon',
  } ,
  {
    title: "General Settings",
    route: 'master-delivery-list',
    icon: 'FolderIcon',
  }]
if (userData.role == 'admin') {
  routes = adminroutes;
}
if (userData.role == 'Staff' ){
   routes = staffroutes;

}

export default [...routes]